import React from 'react';
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import Button from "$components/buttons/button.react";
import './profile-breadcrumb.css';

interface IControllerProfileBreadcrumbProps {
  onNavigateHome: () => void,
  controllerAlias: string,
  profileName: string
}

const ProfileBreadcrumb: React.FC<IControllerProfileBreadcrumbProps> = (
  {
    onNavigateHome,
    controllerAlias,
    profileName
  }) => {
  const [t] = useCaseInsensitiveTranslation();
  
  return (
    <div className="controller-profile-breadcrumb flex row mar_rm">
      <Button variant={"tertiary"} type={"button"} onClick={onNavigateHome}>{controllerAlias}</Button> 
      <span className="mar_rs">{">"}</span> 
      {t('UI_ProfileEditor_ProfileHeader')}
      &nbsp;
      <strong>{profileName}</strong>
    </div>
  );
};

export default ProfileBreadcrumb;
