import React, {useState, useMemo} from 'react';
import {
  CopyToDayEnum,
  ToolBoxBatchEnum
} from "$pages/common/controllers/controller-profile-editor/controller-profile-utility-functions";
import TextField from '$components/textbox/text-field.react';
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import {ensureNumber, isFiniteNumber} from "$lib/numberHelpers";
import Dropdown from "$components/dropdowns/dropdown/dropdown.react";
import Tooltip from "$components/tooltip/tooltip.react";
import {lowerCaseAndTrimString} from "$lib/stringHelpers";
import { isNone } from '$lib/helpers';
import './tool-box-batch-tool.css';

export interface IProfileToolBoxBatchToolComponentProps {
  readonly: boolean
  performBatchOperation: (operation: ToolBoxBatchEnum, value: number | string) => void;
  performCopyValuesToDay: (destinationDay: number) => void;
}

const ProfileToolBoxBatchToolComponent: React.FC<IProfileToolBoxBatchToolComponentProps> = ({
    readonly, 
    performBatchOperation,
    performCopyValuesToDay}) => {
  
  const [operationValue, setOperationValue] = useState<number | string>('');
  const [operation, setOperation] = useState<[number, string] | undefined>(undefined);
  const [t] = useCaseInsensitiveTranslation();

  const operationDropdownItems: [number, string][] = [
    [ToolBoxBatchEnum.SetTo, t('UI_SiteDetails_Controllers_ControllerDetails_ControllerProfiles_ProfileEditor_Formula_Operation_Button_Set')],
    [ToolBoxBatchEnum.Add, t('UI_SiteDetails_Controllers_ControllerDetails_ControllerProfiles_ProfileEditor_Formula_Operation_Button_Add')],
    [ToolBoxBatchEnum.Subtract, t('UI_SiteDetails_Controllers_ControllerDetails_ControllerProfiles_ProfileEditor_Formula_Operation_Button_Subtract')],
    [ToolBoxBatchEnum.Multiply, t('UI_SiteDetails_Controllers_ControllerDetails_ControllerProfiles_ProfileEditor_Formula_Operation_Button_Multiply')],
    [ToolBoxBatchEnum.Divide, t('UI_SiteDetails_Controllers_ControllerDetails_ControllerProfiles_ProfileEditor_Formula_Operation_Button_Divide')],
    [ToolBoxBatchEnum.IntegralArea, t('UI_SiteDetails_Controllers_ControllerDetails_ControllerProfiles_ProfileEditor_Formula_Operation_Button_IntegralArea')]
  ];
  
  const copyDropdownItems: [number, string][] = [
    [CopyToDayEnum.AllDays, t('UI_SiteDetails_Controllers_ControllerDetails_ControllerProfiles_ProfileEditor_Formula_Copy_AllDays')],
    [CopyToDayEnum.Sun, t('UI_Days_Sunday')],
    [CopyToDayEnum.Mon, t('UI_Days_Monday')],
    [CopyToDayEnum.Tue, t('UI_Days_Tuesday')],
    [CopyToDayEnum.Wed, t('UI_Days_Wednesday')],
    [CopyToDayEnum.Thu, t('UI_Days_Thursday')],
    [CopyToDayEnum.Fri, t('UI_Days_Friday')],
    [CopyToDayEnum.Sat, t('UI_Days_Saturday')],
  ];
      
  const onChangeScalar = useMemo(() => (ev: React.ChangeEvent<HTMLInputElement>) => {
    const v = ev.target.value;
    if (isFiniteNumber(v)) {
      setOperationValue(ensureNumber(v));
    }
    else if (lowerCaseAndTrimString(v) === "") {
      setOperationValue("");
    }
  }, [setOperationValue]);
    
  const onOperationClicked = (params: any) => {
    if (params === undefined || params === null || !params.value || !isFiniteNumber(params.value[0])) return;
    setOperation(params.value);
    performBatchOperation(params.value[0], operationValue);
  }
  
  const onCopyToDayClicked = (params: any) => {
    if (isNone(params) || !isFiniteNumber(params.value[0])) return;
    performCopyValuesToDay(params.value[0]);
  }
    
  const labelStyleOverride = { flex: "0 0 125px" };
  
  return (
    <div className="flex column mar_s profile-tool-box-batch-operations">

      <div className="flex_0_0_auto mar_bm">
        {t('UI_SiteDetails_Controllers_ControllerDetails_ControllerProfiles_ProfileEditor_Formula_Description')}
      </div>

      <div className="flex row flex_1 jsb mar_bm">
        <Tooltip customClass={"flex_1 flex"} text={t('UI_SiteDetails_Controllers_ControllerDetails_ControllerProfiles_ProfileEditor_Formula_Operation')}>
          <TextField
            value={operationValue}
            onChange={onChangeScalar}
            disabled={readonly}
            type={"number"}
            placeholder={t('UI_SiteDetails_Controllers_ControllerDetails_ControllerProfiles_ProfileEditor_Formula_Operation')}
          />
        </Tooltip>
        
        <Dropdown<[number, string]>
          className="mar_lm flex_1"
          items={operationDropdownItems}
          selected={operation}
          displayPath={"1"}
          itemClicked={onOperationClicked}
          canSelectBlankValue={false}
          placeholder={t('UI_SiteDetails_Controllers_ControllerDetails_ControllerProfiles_ProfileEditor_Formula_Operation_Button')}
          disabled={!isFiniteNumber(operationValue)}
        />
      </div>
      
      <div className="flex_1 flex row jsb">
        
        <div style={labelStyleOverride} className="flex aicenter trunc-text">
          {t('UI_ControllerProfiles_ProfileEditor_Copy_Values')}
        </div>
        <Tooltip customClass={"flex_1 flex"} text={t('UI_Common_Select_Day')}>
          <Dropdown<[number, string]>
            className="mar_lm flex_2"
            items={copyDropdownItems}
            displayPath={"1"}
            itemClicked={onCopyToDayClicked}
            canSelectBlankValue={false}
            placeholder={t('UI_Common_Select_Day')}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default ProfileToolBoxBatchToolComponent;
