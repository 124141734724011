import {
  ProfileValueTupleType,
  ProfileValueType
} from "$pages/common/controllers/controller-profile-editor/profile-value-types";
import {ensureNumber, isFiniteNumber, roundDownToNDecimals} from "$lib/numberHelpers";
import {isNone} from "$lib/helpers";

export const valueOrZero = (item: ProfileValueTupleType) => !!item[0] ? item[0] : 0;

export const roundProfileValue = (value: number) => roundDownToNDecimals(value, 3);

export const stringifyProfileValue = (v: ProfileValueType): string => isNone(v) ? '' : `${v!}`;

export const numberifyString = (v: string) => isFiniteNumber(v) ? ensureNumber(v!) : undefined;


/**
 * Batch operations to apply to all 24 values in a day, given a single scalar as input:
 */
export enum ToolBoxBatchEnum {
  SetTo,
  Add,
  Subtract,
  Multiply,
  Divide,
  IntegralArea
}

/** 
 * Uses english week day ordering on purpose, because it matches Sun=0, Mon=1 etc used in all the code here. 
 */
export enum CopyToDayEnum {
  Sun,
  Mon,
  Tue,
  Wed,
  Thu,
  Fri,
  Sat,
  AllDays,
}
