import React, { FC } from 'react';
import ProfileValueEditorHeader
  from "$pages/common/controllers/controller-profile-editor/modules/profile-value-editor/profile-value-editor-header";
import ProfileValueEditor
  from "$pages/common/controllers/controller-profile-editor/modules/profile-value-editor/profile-value-editor";
import './profile-value-list.css';
import {
  ProfileValueListType, ProfileValueTupleType
} from '$pages/common/controllers/controller-profile-editor/profile-value-types';
import classNames from "classnames";


interface IProfileValueListProps {
  /**
   * Which day of the week (0..6) to display, which translates directly into an starting offset into the 168 profile values
   */
  day: number;
  readonly: boolean,
  values: ProfileValueListType,
  valueChanged: (valueTuple: ProfileValueTupleType) => void,
  className: string | undefined;
}

const getMinutes = (index: number) => {
  return index * 60;
}

const ProfileValueList: FC<IProfileValueListProps> = (
  {
    day,
    readonly,
    values,
    valueChanged,
    className
  }) => {

  const startingOffset = day >= 0 && day <= 6 ? day * 24 : 0;
  const endingOffset = startingOffset + 24;
  const error = '';
    
  return (
    <div className={classNames('controller-profile-value-editor-list', 'overflow-auto', className)}>
      <ProfileValueEditorHeader/>
      {!!values && values.slice(startingOffset, endingOffset).map((item, index) => (
        <ProfileValueEditor
          index={index} 
          timeMinutes={getMinutes(index)} 
          tupleValue={item} 
          readonly={readonly} 
          valueChangeOnBlur={valueChanged} 
          error={error}
          key={item[1]}
        />
      ))}      
    </div>
  );
};

export default ProfileValueList;
