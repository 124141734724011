import React, {FC, useEffect, useState} from 'react';
import TextField from "$components/textbox/text-field.react";
import './profile-value-editor.css';
import {
  ProfileValueTupleType
} from '$pages/common/controllers/controller-profile-editor/profile-value-types';
import {isFiniteNumber} from "$lib/numberHelpers";
import {numberifyString, stringifyProfileValue} from '../../controller-profile-utility-functions';

interface IProfileValueEditorProps {
  index: number,
  timeMinutes: number,
  tupleValue: ProfileValueTupleType,
  readonly: boolean,
  valueChangeOnBlur: (valueTuple: ProfileValueTupleType) => void,
  error: string
}

const twoDigits = (val: number): string => Math.floor(val).toString().padStart(2, "0");

const minutes_to_hhMM = (timeMinutes: number): string => 
  `${twoDigits(timeMinutes / 60)}:${twoDigits(timeMinutes % 60)}`;

const ProfileValueEditor: FC<IProfileValueEditorProps> = (
  {
    index,
    timeMinutes,
    tupleValue,
    valueChangeOnBlur,
    readonly
  }) => {
  const [valueString, setValueString] = useState<string>(stringifyProfileValue(tupleValue[0]));
  
  useEffect(() => {
    setValueString(stringifyProfileValue(tupleValue[0]))  
  }, [tupleValue]);
  
  const timeHhMM = minutes_to_hhMM(timeMinutes);
    
  const notifyValueChangeWrapper = (): void => {
    const tuple: ProfileValueTupleType = [numberifyString(valueString), tupleValue[1]];
    valueChangeOnBlur(tuple);
  }

  return (
    <div className="controller-profile-value-editor">
      <div className="index-col smaller-text">{index}</div>
      <div className="time-col smaller-text">{timeHhMM}</div>
      <div>
        <TextField 
          value={valueString}
          onChange={evt => setValueString(evt.target.value)}
          onBlur={notifyValueChangeWrapper}
          disabled={readonly}
          type={"number"}
          required={true}
          error={!isFiniteNumber(tupleValue[0]) ? '' : undefined}
          size={"small"}
        />
      </div>
    </div>
  );
}
export default ProfileValueEditor;
