import React, {FC, useEffect, useState} from 'react';
import {Line} from 'chartist';
import {
  ProfileValueListType, ProfileValueTupleType
} from '$pages/common/controllers/controller-profile-editor/profile-value-types';
import './profile-day-graph.css';
import {isNone} from "$lib/helpers";
import {
  isInRect, project, transform
} from "./chartist-utility-functions";
import { roundDownToNDecimals } from "$lib/numberHelpers";
import {
  valueOrZero
} from "$pages/common/controllers/controller-profile-editor/controller-profile-utility-functions";
import classNames from "classnames";

interface IProfileWeekGraphProps {
  className: string | undefined;

  /**
   * Graph values for the current 24 hours to display, possibly edited by the user
   */
  dayValues: ProfileValueListType;

  /**
   * Originally loaded, unmodified, values
   */
  originalValues: ProfileValueListType;   
  
  /**
   * Function to invoke when changing day values by direct mouse manipulation on the graph.
   * @param valueTuple The new [value, weekly_index]
   */
  valueChanged: (valueTuple: ProfileValueTupleType) => void;
  
  /**
   * y-axis minimum selected by the user. If undefined then let chart pick the min value.
   */
  minYAxisSelected: number | undefined,
  
  /**
   * y-axis maximum selected by the user. If undefined then let chart pick the min value.
   */
  maxYAxisSelected: number | undefined
  
  readonly: boolean
}

/**
 * JS Chartist types as used by this code - expected members 
 */
type LocalChartDataMirrorType = {
  axisX: { axisLength: number },
  axisY: { chartRect: { y2: number } }
  chartRect: { x1: number; x2: number; y1: number; y2: number }
  svg: { _node: any }
};

/**
 * xAxisLabels = ['00', '01', '02', ...]
 */
const xAxisLabels: string[] = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));

/**
 * Convert data points into format that chartist uses
 * @param values Current pending values, modified by the user or just the same as "originals"
 * @param originals The originally loaded values, for showing original "shadow" graph in the background of the graph for pending values.
 */
const getLineChartData = (values: ProfileValueListType, originals: ProfileValueListType) =>
  ({ 
    series: values !== undefined && originals !== undefined ? [ values.map(valueOrZero), originals.map(valueOrZero) ] : [[], []],
    labels: xAxisLabels
  });

/**
 * Get options to give to chartist, for configuring up the chart
 * @param yMin y-axis minimum, or undefined to let the chart pick the lowest value
 * @param yMax y-axis max, or undefined to let the chart pick the max value
 * @param onChartCreated A callback for the "created" event on the chart. Usually for getting a reference to a chart object that comes with the event.
 */
const getLineChartOptions = (yMin: number | undefined, yMax: number | undefined, onChartCreated: (chart: unknown) => void) => ({
  fullWidth: true,
  showArea: true,
  showLine: true,
  showPoint: false,
  areaBase: 0,
  axisX: {
    showLabel: true,
    showGrid: true,
  },
  axisY: {
    onlyInteger: true,
    labelOffset: {
      x: 0,
      y: 0
    },
    showGrid: true,
    low: yMin,
    high: yMax
  },
  plugins: [ (chart: any) => {
    chart.on('created', onChartCreated)
  }]
});


const MinimumAllowedChartHeightPixels = 150;

/**
 * Note: The chart may not be lower in height than MinimumAllowedChartHeightPixels, due to a jitter problem that will happen then.
 */
const ProfileDayGraph: FC<IProfileWeekGraphProps> = ({
    className,
    dayValues,
    originalValues,
    valueChanged,
    minYAxisSelected,
    maxYAxisSelected,
    readonly
  }) => {
  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [lineChart, setLineChart] = useState<typeof Line | undefined>(undefined);
  const [chartData, setChartData] = useState<LocalChartDataMirrorType | undefined>(undefined);  // chartData is internal info from chartist

  // get lowest "global" index 0..167 for this particular day's values:  
  const rawMinimumIndex = dayValues !== undefined && dayValues[0] !== undefined ? dayValues[0][1] : undefined;
  const minimumIndex = !isNone(rawMinimumIndex) ? rawMinimumIndex! : 0; 

  
  /**
   * Gets an updated point on the graph (in response to the user's mouse action), or empty array if the action
   * was outside the hit-detection rect.
   * @param clientX mouse event X
   * @param clientY mouse event Y
   * @param xIndexOffset offset to add to x-index before returning from function.
   * @returns The new value for x-index [value, x-index], or empty array if invalid
   */
  const getUpdatedPoint = (clientX: number, clientY: number, xIndexOffset: number): number[] => {
    if (chartData === undefined) return [];
    const svg = chartData.svg._node;
    const { chartRect, axisY, axisX } = chartData;

    // Prevent jitter/annoyances, because at some times the svg isn't finished rendering yet when a mouse event happens:
    if (!svg || svg.clientHeight < MinimumAllowedChartHeightPixels) return [];

    const points = transform(clientX, clientY, svg);

    if (!isInRect(points, chartRect)) return [];

    const yValue = roundDownToNDecimals(-project(points.y - axisY.chartRect.y2, axisY), 2);

    const hitX = points.x - chartRect.x1;
    const columnWidth = axisX.axisLength / 23;
    const column = hitX / columnWidth;
    const xIndex = Math.round(column);

    if (xIndex < 0) return [];
    return [yValue, xIndex + xIndexOffset];
  }

  
  const mouseDownOnGraph = (ev: React.MouseEvent) => {
    if (!chartData || readonly) {
      ev.preventDefault();
      return;
    }
    setIsDrawing(true);
    const valueTuple = getUpdatedPoint(ev.clientX, ev.clientY, minimumIndex);
    if (valueTuple.length === 2) {
      valueChanged([valueTuple[0], valueTuple[1]]);
    }
    ev.preventDefault();
  };


  const mouseMoveOnGraph = (ev: React.MouseEvent) => {
    if (!chartData || !isDrawing || readonly) {
      ev.preventDefault();
      return;
    }
    const valueTuple = getUpdatedPoint(ev.clientX, ev.clientY, minimumIndex);
    if (valueTuple.length === 2) {
      valueChanged([valueTuple[0], valueTuple[1]]);
    }
    ev.preventDefault();
  }


  /**
   * Warning: do not do ev.preventDefault() in this handler. It will make number inputs behave strangely when clicking their up/down buttons.
   */
  const mouseUpOnWindow = () => {
    if (!readonly) {
      setIsDrawing(false);
    }
  }

  
  useEffect(() => {
    window.addEventListener('mouseup', mouseUpOnWindow);
    return () => window.removeEventListener('mouseup', mouseUpOnWindow);
  }, [])


  /**
   * This effect runs after data is updated. You cannot run this logic in the effect that runs after first render.
   */
  useEffect(() => {
    if (!dayValues || dayValues.length < 0) return;

    const data = getLineChartData(dayValues, originalValues);
    const options = getLineChartOptions(minYAxisSelected, maxYAxisSelected, (chart: LocalChartDataMirrorType) => setChartData(chart));

    if (lineChart === undefined) {
      const _lineChart = new Line('#controllerProfileReactDayGraph', data, options)
      setLineChart(_lineChart);

    } else {
      lineChart!.update(data, options);   // refresh existing line chart with the newest data points
    }
  }, [dayValues, maxYAxisSelected, minYAxisSelected, lineChart, setLineChart]);  
  
  
  return (
    <div className={classNames('controller-profile-day-graph', className)}>
      <div id={"controllerProfileReactDayGraph"} className="dayGraph" onMouseDown={mouseDownOnGraph} onMouseMove={mouseMoveOnGraph}></div>
    </div>
  );
};

export default ProfileDayGraph;
