import React, {FC, useEffect, useState} from 'react';
import {Interpolation, Line} from 'chartist';
import {
  ProfileValueListType, ProfileValueTupleType
} from '$pages/common/controllers/controller-profile-editor/profile-value-types';
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import './profile-week-graph.css';
import {isNone} from "$lib/helpers";
import classNames from "classnames";

interface IProfileWeekGraphProps {
  className: string | undefined;
  values: ProfileValueListType;
  onSelectDay: (day: number) => void;
  selectedDay: number;
}

// Day graph setup helper functions: 
// 
const valueOrZero = (item: ProfileValueTupleType) => !!item[0] ? item[0] : 0; 

const pickMaximum = (a: number, b: number) => a > b ? a : b; 

const getLineChartData = (values: ProfileValueListType) =>
  ({ series: values !== undefined ? [ values.map(valueOrZero) ] : [[]] });

const getMaxY = (valueSeries: number[]) => valueSeries.reduce(pickMaximum);

const getLineChartOptions = (yMax: number) => ({
  showArea: true,
    showLine: true,
    showPoint: false,
    lineSmooth: Interpolation.simple(),
    chartPadding: {
    bottom: 10,
      left: 0,
      right: 0,
      top: 10
  },
  axisX: {
    showLabel: false,
      showGrid: false,
      offset: 0
  },
  axisY: {
    showLabel: false,
      showGrid: false,
      offset: 0,
  ...(isNone(yMax) ? {} : {high: yMax}),
      low: 0
  }
});


const ProfileWeekGraph: FC<IProfileWeekGraphProps> = ({
    className,
    values,
    onSelectDay,
    selectedDay
  }) => {
  const [lineChart, setLineChart] = useState<typeof Line | undefined>(undefined);
  const [t] = useCaseInsensitiveTranslation();

  // Setup line chart first time after first render:
  useEffect(() => {
    if (values === undefined || values.length < 1) return;
    const data = getLineChartData(values);
    const maxY = getMaxY(data.series[0])                // highest Y seen in this day's data
    const options = getLineChartOptions(maxY);
    const lineChart = new Line('#controllerProfileReactWeekGraph', data, options)

    setLineChart(lineChart);
  }, []);

  // Update line chart graph when values prop changes:
  useEffect(() => {
    if (lineChart === undefined || values === undefined || values.length < 1) return;
    const data = getLineChartData(values);
    const maxY = getMaxY(data.series[0])
    const options = getLineChartOptions(maxY);

    lineChart.update(data, options);
  }, [values]);
    
  const weekDayKeys = ['UI_Days_Sunday', 'UI_Days_Monday', 'UI_Days_Tuesday', 
    'UI_Days_Wednesday', 'UI_Days_Thursday', 'UI_Days_Friday', 'UI_Days_Saturday'];                         
  
  const weekDayDivs = weekDayKeys.map((dayKey, dayNumber) => (
    <div
      key={dayNumber}
      className={classNames('weekDay', selectedDay === dayNumber && 'selected')}
      onMouseUp={() => onSelectDay(dayNumber)}>
      { t(dayKey) }
    </div>
  ));
  
  return (
    <div className={classNames('controller-profile-week-graph', className)}>
      {weekDayDivs}
      <div id={"controllerProfileReactWeekGraph"} className="weekGraph fullWidth"></div>
    </div>
  );
};

export default ProfileWeekGraph;
