import React, {FC, useEffect, useState} from 'react';
import {mutate, useQuery} from '$lib/hooks/fetch-utillities';
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import {
  ControllerProfileEditorDocument,
  ControllerProfileEditorQuery,
  UpdateControllerProfileValuesDocument
} from '$typings/graphql-codegen';
import {ensureNumber, isFiniteNumber} from '$lib/numberHelpers';
import {isNone, isSomething} from "$lib/helpers";
import Button from "$components/buttons/button.react";
import ErrorText from "$components/texts/error-text/error-text.react";
import Skeleton from "$components/placeholders/skeleton/skeleton";
import {
  ProfileValueListType,
  ProfileValueTupleType,
} from "./profile-value-types";
import ProfileValueList from "./modules/profile-value-list/profile-value-list";
import ProfileWeekGraph
  from "$pages/common/controllers/controller-profile-editor/modules/profile-week-graph/profile-week-graph";
import ProfileDayGraph
  from "$pages/common/controllers/controller-profile-editor/modules/profile-day-graph/profile-day-graph";
import './controller-profile-editor.css';
import ProfileBreadcrumb
  from "$pages/common/controllers/controller-profile-editor/modules/profile-breadcrumb/profile-breadcrumb";
import {IHistoryItem} from "$interfaces/application";
import ProfileHistoryList
  from "$pages/common/controllers/controller-profile-editor/modules/profile-history-list/profile-history-list";
import Card from '$components/cards/card/card.react';
import ProfileToolBox
  from "$pages/common/controllers/controller-profile-editor/modules/profile-tool-box/profile-tool-box";
import ControllerProfileTitleBar
  from "$pages/common/controllers/controller-profile-editor/modules/profile-title-bar/profile-title-bar";
import {
  CopyToDayEnum,
  ToolBoxBatchEnum,
  roundProfileValue
} from "$pages/common/controllers/controller-profile-editor/controller-profile-utility-functions";
import LoadingBars from "$components/loading-bars/loading-bars.react";
import {getTimezoneById} from "$lib/timezone/timezoneHelpers";
import {convertToTimezone} from "$lib/dateHelpers";
import {WarningText} from "$components/texts/warning-text/warning-text.react";
import Icon from "$components/icons/icon/icon.react";


export type DownloadProfileExcelFunc = (controllerId: number, controllerProfileId: number, controllerProfileHistoryId: number) => Promise<void>; 

export interface IControllerProfileEditorProps {
  controllerId: string;
  profileId: string;
  historyId?: string;
  onSave: () => void,
  onCancel: () => void,
  onNavToProfileHistory: (historyId: number) => void,
  onDownloadProfileExcel: DownloadProfileExcelFunc
}

const sanitizeNumberFromString = (str: string | null | undefined): number =>  
  str ? ensureNumber(str) : 0;

const sanitizeString = (str: string | null | undefined): string =>
  (!!str && (`${str}`.length > 0)) ? str : '';  

const dayToString = (day: number): string =>
  (day >= 0 && day <= 6 ? [
    'UI_Days_Sunday',
    'UI_Days_Monday',
    'UI_Days_Tuesday',
    'UI_Days_Wednesday',
    'UI_Days_Thursday',
    'UI_Days_Friday',
    'UI_Days_Saturday'    
  ][day] : 'UNKNOWN');


type ParseDataFuncReturnType = {
  controllerAlias: string,
  profileName: string,
  valuesAreReadonly: boolean,
  allWeekOriginalValues: ProfileValueListType,
  profileHistoryItems: IHistoryItem[],
  controllerTimezoneId: number | undefined
}

/**
 * Wraps the messy parts of picking out values from GQL data. Ensures result is object without nulls and undefines.
 * @param t text translation function
 * @param downloadExcelProfile function taking three IDs, which is executed when clicking one of the "download" links in the profile history
 * @param loadProfileHistoryId function that when called will (somehow) load a certain history id version of the profile data 
 * @param sanitizedHistoryId currently selected profile history id (may be undefined for "latest") 
 * @param data data loaded from GQL API
 */
const parseDataIfAvailable = (
  t: (x: string) => string,
  downloadExcelProfile: DownloadProfileExcelFunc,
  loadProfileHistoryId: ((id: number | undefined) => void),
  sanitizedHistoryId: number | undefined,
  data: ControllerProfileEditorQuery | undefined): ParseDataFuncReturnType => {
  
  if (data === undefined || (data?.controller === undefined) || (data?.controller?.profile === undefined) || 
    (data?.controller?.profile?.profilevalues  === undefined) || (data.controller?.profile?.history  === undefined))
  {
    return ({
      controllerAlias: '', 
      profileName: '', 
      valuesAreReadonly: true, 
      allWeekOriginalValues: undefined, 
      profileHistoryItems: [],
      controllerTimezoneId: undefined
    });
  }
  
  const sanitizedControllerId = sanitizeNumberFromString(data!.controller!.controllerId);
  const valueTuples = data!.controller!.profile!.profilevalues!.profileValues.map((item, index) => [item, index]);
  const profileValuesList = valueTuples as ProfileValueListType;
  
  return ({
    controllerAlias: sanitizeString(data!.controller!.alias),
    profileName: sanitizeString(data!.controller!.profile!.name),
    valuesAreReadonly: data!.controller!.profile!.profilevalues!.isReadOnly,
    allWeekOriginalValues: profileValuesList,
    profileHistoryItems: data!.controller!.profile!.history.map(item => ({
        timeStamp: item.created,
        text: [ item.user?.name ],
        externalLinks: [ {
          text: t('UI_Common_Download'),
          action: () => downloadExcelProfile(sanitizedControllerId, item.controllerProfileId, item.controllerProfileHistoryId)
        } ],
        dataForOnClick: { historyId: item.controllerProfileHistoryId},
        onClick: (data) => {
          // click handler for profile history list items (right hand side)
          if (!isNone(data) && !isNone(data.historyId)) {
            loadProfileHistoryId(data.historyId!);
          }
          return Promise.resolve(); 
        },
        class: `clickable ${item.controllerProfileHistoryId === sanitizedHistoryId ? 'active' : ''}`
    })),
    controllerTimezoneId: isNone(data!.controller!.timeZoneId) ? undefined : data!.controller!.timeZoneId! 
  });
}

/**
 * Add string "Latest" to the newest history item
 * @param t Translation function
 * @param sanitizedHistoryId currently selected profile history id (may be undefined for "latest")
 * @param parsedData data sanitized by the parseDataIfAvailable function
 */
const markLatestHistoryItem = (
  t: (x: string) => string,
  sanitizedHistoryId: number | undefined,
  parsedData: ParseDataFuncReturnType) => {
  
  if (parsedData.profileHistoryItems.length > 0) {
    let latest = parsedData.profileHistoryItems[0]; 
    latest.metaInformation = t('UI_ProfileEditor_ProfileHistory_Latest');
    if (isNone(sanitizedHistoryId)) {
        latest.class = `${latest.class} active`; 
    }
  }
  return parsedData;
}

/**
 * Profile history timestamp strings are initially UTC. This function converts them to the controller's time zone.
 * @param parseDataFuncReturnType bag of data being parsed and converted from GraphQL data 
 */
const shiftHistoryDatesToControllerTimezone = (parseDataFuncReturnType: ParseDataFuncReturnType): ParseDataFuncReturnType => {
  if (isNone(parseDataFuncReturnType.controllerTimezoneId)) return parseDataFuncReturnType;
  
  const controllerTimezone = getTimezoneById(parseDataFuncReturnType.controllerTimezoneId);
  if (isNone(controllerTimezone)) return parseDataFuncReturnType;
  
  for (let historyItem of parseDataFuncReturnType.profileHistoryItems) {
    historyItem.timeStamp = convertToTimezone(controllerTimezone, new Date(historyItem.timeStamp)).toISO();    // results like "2023-09-01T06:21:00.000-04:00" 
  }
  return parseDataFuncReturnType;
};


const getSelectedDayValues = (allWeekValues: ProfileValueListType, selectedDay: number): ProfileValueTupleType[] => {
  if (allWeekValues === undefined || allWeekValues.length !== 168 || selectedDay < 0 || selectedDay > 6) {
    return [];
  }
  const offset = selectedDay * 24;
  return allWeekValues.slice(offset, offset + 24);
}

const ControllerProfileEditorComponent: FC<
  IControllerProfileEditorProps
> = ({
  controllerId,
  profileId, 
  historyId,
  onSave,
  onCancel, 
  onNavToProfileHistory,
  onDownloadProfileExcel
}: IControllerProfileEditorProps) => {
  const [selectedControllerId] = useState(controllerId);
  const [selectedProfileId] = useState(profileId);
  const [selectedHistoryId, setSelectedHistoryId] = useState(historyId);
  const [selectedDay, setSelectedDay] = useState(0);
  const [pendingValues, setPendingValues] = useState<ProfileValueListType>(undefined);
  const [errorKey, setErrorKey] = useState('');
  const [saving, setSaving] = useState(false);
  const [allValuesValid, setAllValuesValid] = useState(true);
  const [minYAxisSelected, setMinYAxisSelected] = useState<number | undefined>(undefined);
  const [maxYAxisSelected, setMaxYAxisSelected] = useState<number | undefined>(undefined);
  const [toolboxExpanded, setToolboxExpanded] = useState<boolean>(false);
  const [t] = useCaseInsensitiveTranslation();
  
  if (isNone(selectedControllerId)) throw new Error('controllerId undefined');
  if (isNone(selectedProfileId)) throw new Error('profileId undefined');
  if (isNone(onSave)) throw new Error('onSave() undefined');
  if (isNone(onCancel)) throw new Error('onCancel() undefined');
  if (isNone(onNavToProfileHistory)) throw new Error('onNavToProfileHistory() undefined');
  if (isNone(onDownloadProfileExcel)) throw new Error('onDownloadProfileExcel() undefined');
  
  const sanitizedControllerId = sanitizeNumberFromString(selectedControllerId);
  const sanitizedProfileId = sanitizeNumberFromString(selectedProfileId);
  const sanitizedHistoryId = isNone(selectedHistoryId) || !isFiniteNumber(selectedHistoryId) ? undefined : ensureNumber(selectedHistoryId);
  
  const { data, loading, error, revalidate, deleteCachedData } = useQuery(ControllerProfileEditorDocument, 
      { 
        controllerId: sanitizedControllerId,
        profileId: sanitizedProfileId,
        profileHistoryId: sanitizedHistoryId
      }, 
      {
        revalidateOnEvents: false          
      });

  /**
   * When clicking on one of the profile history items, this function ensures only the historical values are loaded instead:
   */
  const loadHistoricalProfileById = (historyId: number | undefined) => {
    if (isNone(historyId) || !isFiniteNumber(historyId)) return;
    if (isFiniteNumber(selectedHistoryId) && ensureNumber(selectedHistoryId!) == historyId) return;  // don't load if historyId is the same as before

    deleteCachedData();   // avoid a problem where you load historical profile, edit it, save, then re-open the profile and see the previous "latest" profile (cached) before the newly created latest profile is then loaded by the GQL layer. 
    setSelectedHistoryId(`${historyId}`);
    setPendingValues(undefined);
    onNavToProfileHistory(historyId);
  }

  // Parse, convert, annotate any loaded data:
  const {
    controllerAlias,
    profileName,
    valuesAreReadonly,
    allWeekOriginalValues,
    profileHistoryItems,
  } = shiftHistoryDatesToControllerTimezone(
        markLatestHistoryItem(t, sanitizedHistoryId, 
          parseDataIfAvailable(t, onDownloadProfileExcel, loadHistoricalProfileById, sanitizedHistoryId, data)));
  
  const selectedDayValues = getSelectedDayValues(pendingValues, selectedDay);
  const selectedDayOriginalValues = getSelectedDayValues(allWeekOriginalValues, selectedDay);
  
  const scanForInvalidValues = (valueList: ProfileValueTupleType[]): void => {
    if (valueList.findIndex(val => !isFiniteNumber(val[0])) >= 0) {
      setAllValuesValid(false);
      return;
    } 
    setAllValuesValid(true);
  }
  
  const updatePendingValueInState = (valueTuple: ProfileValueTupleType): void => {
    if (!!pendingValues && pendingValues.length > 0) {
      let updatedPendingValues = [...pendingValues];
      updatedPendingValues[valueTuple[1]] = valueTuple;
      setPendingValues(updatedPendingValues);
      scanForInvalidValues(updatedPendingValues);   
    } 
  }
  
  const updateMultiplePendingValuesInState = (valueList: ProfileValueTupleType[]): void => {
    if (!!pendingValues && pendingValues.length > 0) {
      let updatedPendingValues = [...pendingValues];
      for (const valueTuple of valueList) {
        updatedPendingValues[valueTuple[1]] = valueTuple;
      }
      setPendingValues(updatedPendingValues);
      scanForInvalidValues(updatedPendingValues);
    }
  }
    
  const saveProfileByMutating = async (): Promise<void> => {
    setSaving(true);

    const profileValues: number[] | undefined = pendingValues && 
      pendingValues
        .map(item => item[0])
        .filter(isFiniteNumber) as number[];
    
    if (profileValues === undefined || profileValues.length !== 168) {
      setErrorKey('UI_Common_Error_Save_Item_Failed');
      setSaving(false);
    }
    else {
      await mutate(
        UpdateControllerProfileValuesDocument,
        {
          controllerId: sanitizedControllerId,
          profileId: sanitizedProfileId,
          profileValues,
        },
        false,   // no point, due nav away from page and to delete cached data onSuccess
        () => {
          deleteCachedData();    // avoid a problem where you load, edit, save, and then re-open, only to see the previous latest profile (cached data), before getting the latest one loaded.
          onSave && onSave();
        },
        () => {
          setErrorKey('UI_Common_Error_Save_Item_Failed');
          setSaving(false);
        }
      );
    }
  }

  const onYMinAxisSelected = (v: string) => {
    const newVal = isFiniteNumber(v) ? ensureNumber(v) : undefined;
    setMinYAxisSelected(newVal);
  }

  const onYMaxAxisSelected = (v: string) => {
    const newVal = isFiniteNumber(v) ? ensureNumber(v) : undefined;
    setMaxYAxisSelected(newVal);
  }

  const getBatchOperation = (operation: ToolBoxBatchEnum, inputtedValue: number, pendingValuesOnSelectedDay: ProfileValueTupleType[])
    : ((item: ProfileValueTupleType) => ProfileValueTupleType) => {
    
    if (operation === ToolBoxBatchEnum.SetTo) {
      return (item => [inputtedValue, item[1]] as ProfileValueTupleType);
    }
    if (operation === ToolBoxBatchEnum.Add) {
      return (item => [isSomething(item[0]) ? roundProfileValue(item[0] + inputtedValue) : undefined, item[1]] as ProfileValueTupleType);
    }
    if (operation === ToolBoxBatchEnum.Subtract) {
      return (item  => [isSomething(item[0]) ? roundProfileValue(item[0] - inputtedValue) : undefined, item[1]] as ProfileValueTupleType);
    }
    if (operation === ToolBoxBatchEnum.Multiply) {
      return (item => [isSomething(item[0]) ? roundProfileValue(item[0] * inputtedValue) : undefined, item[1]] as ProfileValueTupleType);
    }
    if (operation === ToolBoxBatchEnum.Divide) {
      return ((item: ProfileValueTupleType): ProfileValueTupleType => {
        const value = item[0];
        if (isNone(value) || inputtedValue === 0) {
          return [0, item[1]];
        }
        return [roundProfileValue(value! / inputtedValue), item[1]];
      });
    }
    if (operation === ToolBoxBatchEnum.IntegralArea) {
      const daySum = pendingValuesOnSelectedDay
        .map(item => item[0])
        .filter(item => isFiniteNumber(item))
        .reduce((prev: number, current: number) => prev + current) as number;

      return ((item: ProfileValueTupleType): ProfileValueTupleType => {
        const value = item[0];
        if (isNone(value) || value === 0 || inputtedValue === 0) {
          return [0, item[1]];
        }
        return [roundProfileValue(value! / daySum * inputtedValue), item[1]];
      });
    }
    return (item) => item;
  }
  
  
  const performBatchOperation = (operation: ToolBoxBatchEnum, value: number | string) => {
    if (pendingValues === undefined) return;
    if (!isFiniteNumber(value)) return;
    const valueNumber = ensureNumber(value);
    const dayOffset = selectedDay * 24;
    const pendingValuesOnSelectedDay = pendingValues.slice(dayOffset, dayOffset+24);
    
    const funcForOperation = getBatchOperation(operation, valueNumber, pendingValuesOnSelectedDay);
    const updatedValues = pendingValuesOnSelectedDay.map(funcForOperation);
    updateMultiplePendingValuesInState(updatedValues);
    return;
  };

  const performCopyValuesToDay = (destinationDay: CopyToDayEnum): void => {
    if (!isFiniteNumber(destinationDay) 
      || !(Object.values(CopyToDayEnum).includes(destinationDay))
      || pendingValues === undefined) {
      return;
    }
    const selectedDayOffset = selectedDay * 24;
    const pendingValuesSelected = pendingValues.slice(selectedDayOffset, selectedDayOffset+24);
    const replacementValues: ProfileValueTupleType[] = [];

    if (destinationDay === CopyToDayEnum.AllDays) {
      for (let i=0; i<168; i++) {
        const valueToCopy = pendingValuesSelected[i % 24][0];
        replacementValues.push([valueToCopy, i]);
      } 
    } else {
      const destDayOffset = destinationDay * 24;
      const destinationDayValues = pendingValues.slice(destDayOffset, destDayOffset + 24);

      for (let i = 0; i < 24; i++) {
        replacementValues.push([pendingValuesSelected[i][0], destinationDayValues[i][1]]);
      }
    }
    updateMultiplePendingValuesInState(replacementValues);
  }
  
  const undoEdits = (): void => {
    if (allWeekOriginalValues === undefined) return;
    updateMultiplePendingValuesInState(allWeekOriginalValues);
  }

  useEffect(() => {
    if (pendingValues === undefined) {
      setPendingValues(allWeekOriginalValues);    // initialize pending values once data is loaded
    }
    allWeekOriginalValues !== undefined && scanForInvalidValues(allWeekOriginalValues);
  }, [data])
  
  return (
    <Card className="flex_1 flex column mar_m profile_editor_min_width_fix">
      <div className="controller-profile-editor flex_1 flex column overflow-auto">
        {(loading && !error) && (
            <div className="flex_1 mar_tm">
              <Skeleton rowCount={1} />
              <div className="three-cols mar_tm">
                <div className="left-col">
                  <Skeleton rowCount={16} />
                </div>
                <div className="middle-col mar_lm">
                    <div className="middle-col-top flex column jcaround">
                      <LoadingBars/>
                    </div>
                    <div className="middle-col-middle">
                      <Skeleton rowCount={5} />
                    </div>
                </div>
                <div className="right-col mar_lm">
                  <Skeleton rowCount={16} />
                </div>
              </div>
            </div>  
        )}
        {error && (
            <div className="controller-profile-editor flex_1 flex column aicenter mar_t2">
                <ErrorText>{t("UI_Common_Error_Loading_Data_Failed")}</ErrorText>
                <div className="flex mar_t2 aicenter">
                  <Button variant={"secondary"} onClick={() => { onCancel && onCancel(); }}>{t('UI_Cancel')}</Button>
                  <Button variant={"secondary"} onClick={() => revalidate && revalidate()} disabled={!revalidate} processing={loading} className="mar_lm">{t("UI_Common_Try_Again")}</Button>
                </div>
            </div>
        )}
        {!loading && !error && !!pendingValues && (
          <>
            <ControllerProfileTitleBar title={t("UI_ProfileEditor_Title") + ": " + t(dayToString(selectedDay))}>
              {valuesAreReadonly && (
                <div className="flex flex_0_0_auto aicenter mar_lm">
                  <Icon name={"alert-triangle"} className="readonly-warning-icon"></Icon>
                  <WarningText bold={true} className="aicenter">{t("UI_ProfileEditor_Read_Only_Data_Loaded_Due_To_No_Contact_With_Controller")}</WarningText>
                </div>)}              
              <ProfileBreadcrumb profileName={profileName} controllerAlias={controllerAlias} onNavigateHome={onCancel}/>
            </ControllerProfileTitleBar>
            <div className="three-cols">
              <div className="left-col">
                <div className="left-col-top">
                  <ProfileValueList
                      className="flex_1"
                      day={selectedDay}
                      readonly={valuesAreReadonly}
                      values={pendingValues}
                      valueChanged={updatePendingValueInState}
                  />
                </div>
                <div className="left-col-bottom">
                  <ProfileToolBox min={minYAxisSelected} 
                                  max={maxYAxisSelected}
                                  onSetMin={onYMinAxisSelected}
                                  onSetMax={onYMaxAxisSelected}
                                  readonly={valuesAreReadonly}
                                  performBatchOperation={performBatchOperation}
                                  performCopyValuesToDay={performCopyValuesToDay}
                                  expanded={toolboxExpanded}
                                  setExpanded={setToolboxExpanded}/>
                </div>
              </div>
              <div className="middle-col">
                <ProfileDayGraph className="middle-col-top"
                                 dayValues={selectedDayValues}
                                 originalValues={selectedDayOriginalValues}
                                 valueChanged={updatePendingValueInState}
                                 maxYAxisSelected={maxYAxisSelected}
                                 minYAxisSelected={minYAxisSelected}
                                 readonly={valuesAreReadonly}>
                </ProfileDayGraph>
                <ProfileWeekGraph className="middle-col-middle"
                                  values={pendingValues}
                                  onSelectDay={setSelectedDay}
                                  selectedDay={selectedDay}>
                </ProfileWeekGraph>
  
                <div className="middle-col-bottom">
                  {errorKey && <ErrorText className="center-content mar_rm">{t(errorKey)}</ErrorText>}
                  <Button variant={"secondary"} onClick={() => { onCancel && onCancel(); }}>{t('UI_Cancel')}</Button>
                  <Button variant={"secondary"} onClick={undoEdits} className="mar_ls">{t('UI_Common_Reset')}</Button>
                  <Button variant={"primary"} onClick={saveProfileByMutating} processing={saving} className="mar_ls" disabled={!allValuesValid}>
                    {t('UI_Common_SaveChanges')}
                  </Button>
                </div>
              </div>
              <div className="right-col">
                <ProfileHistoryList profileHistoryItems={profileHistoryItems}/>
              </div>            
            </div>
          </>
          )}
      </div>
    </Card>
  );
};

export const ControllerProfileEditor = ControllerProfileEditorComponent;
