import { autoinject } from 'aurelia-framework';
import { Router } from "aurelia-router";
import { ReactWrapper } from '$pages/common/react-wrapper';
import { ControllerProfileEditor, IControllerProfileEditorProps } from './controller-profile-editor';
import { ControllerService } from '$services/controllerService';
import { routeNames } from "../../../../config";

export interface ISiteDetailsProfileDetailsURLParams extends IControllerProfileEditorProps {
}

/**
 * A hack to get the name of parent based on contents of the current router URL
 * @param router Aurelia router
 */
const getRouteNameForNavToParent = (router: Router): string => {
  if (router.baseUrl && router.baseUrl.indexOf("/sitedetails/") >= 0) {
    return routeNames.sitedetailsControllerDetails;
  }
  return routeNames.controllerManagerControllerDetails;
}


/**
 * Aurelia wrapper for controller-profile-editor, to make it reachable by using the Au. router.
 */
@autoinject()
export class SiteDetailsProfileDetailsWrapper extends ReactWrapper<
  IControllerProfileEditorProps,
  ISiteDetailsProfileDetailsURLParams
> {
  constructor(router: Router, controllerService: ControllerService) {
    super(
      ControllerProfileEditor,
      (urlParams) => ({
        ...urlParams,
        onSave: () => { router.navigateToRoute(getRouteNameForNavToParent(router), { controllerId: urlParams.controllerId })},
        onCancel: () => { router.navigateToRoute(getRouteNameForNavToParent(router), { controllerId: urlParams.controllerId })},
        onNavToProfileHistory: (historyId: number) => {
          router.navigateToRoute(routeNames.sitedetailsProfileDetails, 
              { 
                  controllerId: urlParams.controllerId, 
                  profileId: urlParams.profileId, 
                  historyId: historyId 
              },
              { 
                  trigger: false 
              });
        },
        onDownloadProfileExcel: async (controllerId: number, controllerProfileId: number, controllerProfileHistoryId: number) => controllerService.downloadControllerProfileAsExcel(controllerId, controllerProfileId, controllerProfileHistoryId)
      })
    );
  }
}
