import React, {FC} from "react";
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";
import './profile-value-editor.css';

const ProfileValueEditorHeader: FC = () => {
  const [t] = useCaseInsensitiveTranslation();
  
  return (
    <div className="controller-profile-value-editor">
      <div className="index-col trunc-text">{t("UI_ProfileEditor_Value_Index")}</div>
      <div className="time-col trunc-text">{t("UI_ProfileEditor_Value_Time")}</div>
      <div className="value-col trunc-text">{t("UI_ProfileEditor_Value_Value")}</div>
    </div>
  );
}
export default ProfileValueEditorHeader;
