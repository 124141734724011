import React, {useState} from 'react';
import TextField from "$components/textbox/text-field.react";
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import {
  stringifyProfileValue
} from "$pages/common/controllers/controller-profile-editor/controller-profile-utility-functions";

export interface IProfileToolBoxScalingProps {
  min: number | undefined,
  max: number | undefined,
  readonly: boolean,
  onSetMin: (n: string) => void,
  onSetMax: (n: string) => void
}


/**
 * Component for setting y-axis on main profile graph to a min and max value.
 * @constructor
 */
const ProfileToolBoxScalingComponent: React.FC<IProfileToolBoxScalingProps> = ({ min, max, onSetMin, onSetMax, readonly }) => {
  const [minString, setMinString] = useState<string>(stringifyProfileValue(min));
  const [maxString, setMaxString] = useState<string>(stringifyProfileValue(max));
  const [t] = useCaseInsensitiveTranslation();
    
  
  return (
    <div className="flex column mar_s">
      
      <span className="flex_0_0_auto mar_bm">
        {t('UI_SiteDetails_Controllers_ControllerDetails_ControllerProfiles_ProfileEditor_SetYScale')}
      </span>
      
      <div className="flex row flex_1 jsb">
      
        <TextField
          value={minString}
          onChange={ev => setMinString(ev.target.value)}
          onBlur={() => onSetMin(minString)}
          disabled={readonly}
          type={"number"}
          placeholder={t('UI_SiteDetails_Controllers_ControllerDetails_ControllerProfiles_ProfileEditor_YScale_Min')}
        />

        <TextField
          className="mar_lm"
          value={maxString}
          onChange={ev => setMaxString(ev.target.value)}
          onBlur={() => onSetMax(maxString)}
          disabled={readonly}
          type={"number"}
          placeholder={t('UI_SiteDetails_Controllers_ControllerDetails_ControllerProfiles_ProfileEditor_YScale_Max')}
        />
      </div>
    </div>
  );
};

export default ProfileToolBoxScalingComponent;
