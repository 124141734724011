import React from 'react';

interface IProfileTitleBar {
  title: string;
}

const ControllerProfileTitleBar: React.FC<IProfileTitleBar> = ({ title, children }) => {
  return (
    <div className="controller-profile-title-bar flex jsb ai-end">
      <h3>{title}</h3>
      {children}
    </div>
  );
};

export default ControllerProfileTitleBar;
