
// Day graph "drawing" (direct manipulation) helper functions, copied from original profilechart.ts: 
// 
export const isInRect = (
  point: { x: number; y: number },
  rect: { x1: number; x2: number; y1: number; y2: number }
) => {
  return point.x >= rect.x1 &&
    point.x <= rect.x2 &&
    point.y >= rect.y2 &&
    point.y <= rect.y1;
}

export const transform = (
  x: number,
  y: number,
  svgElement: any
): { x: number; y: number } => {
  const svg =
    svgElement.tagName === 'svg' ? svgElement : svgElement.ownerSVGElement;
  const matrix = svg.getScreenCTM();
  let point = svg.createSVGPoint();
  point.x = x;
  point.y = y;
  point = point.matrixTransform(matrix.inverse());
  return point || { x: 0, y: 0 };
};

export const project = (value: number, axis: any) => {
  return (value * axis.bounds.range) / axis.axisLength - axis.bounds.max;
};
