import React, { memo } from 'react';
import {HistoryList} from "$components/history-list/history-list/history-list.react";
import {IHistoryItem} from "$interfaces/application";
import './profile-history-list.css';

interface IProfileHistoryListProps {
  profileHistoryItems: IHistoryItem[],
}

const ProfileHistoryList: React.FC<IProfileHistoryListProps> = ({
   profileHistoryItems
 }) => {
  return (
    <div className="controller-profile-history-list flex_1 overflow-auto">
      <HistoryList items={profileHistoryItems} convertTimezone={false}></HistoryList>
    </div>
  );
};

export default memo(ProfileHistoryList);
