import Icon from '$components/icons/icon/icon.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import React from 'react';
import './profile-tool-box.css';
import ProfileToolBoxScalingComponent
  , {
  IProfileToolBoxScalingProps
} from "$pages/common/controllers/controller-profile-editor/modules/profile-tool-box/modules/tool-box-scaling/tool-box-scaling";
import ProfileToolBoxBatchToolComponent
  , {
  IProfileToolBoxBatchToolComponentProps
} from "$pages/common/controllers/controller-profile-editor/modules/profile-tool-box/modules/tool-box-batch-tool/tool-box-batch-tool";

interface IProfileToolBox extends IProfileToolBoxScalingProps, IProfileToolBoxBatchToolComponentProps {
    expanded: boolean,
    setExpanded: (x: boolean) => void,
}

const ProfileToolBox: React.FC<IProfileToolBox> = ({
  min, max, onSetMin, onSetMax, readonly,
  performBatchOperation,
  performCopyValuesToDay,
  expanded,
  setExpanded
 }) => {
  const [t] = useCaseInsensitiveTranslation();
  
  const componentStyleOverride = expanded ? { flex: "0 0 290px", maxWidth: "280px" } : { maxWidth: "280px" };
  const chevronCompo = expanded ? (<Icon name={"chevron-down"}/>) : (<Icon name={"chevron-up"}/>)
  
  return (
    <div className="controller-profile-tool-box" style={componentStyleOverride}>
      <div className="profile-tool-box-header clickable" onClick={() => setExpanded(!expanded)}>
        <Icon name={"settings"}/>
        <span>{t("UI_ProfileEditor_Tools")}</span>
        {chevronCompo}
      </div>
      <div>
        <ProfileToolBoxBatchToolComponent readonly={readonly} performBatchOperation={performBatchOperation}
          performCopyValuesToDay={performCopyValuesToDay}>
        </ProfileToolBoxBatchToolComponent>
      </div>
      <div>
        <ProfileToolBoxScalingComponent min={min} max={max} onSetMax={onSetMax} onSetMin={onSetMin} readonly={readonly}/>
      </div>
    </div>
  );
};

export default ProfileToolBox;
